/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';
import { Link } from 'gatsby';

class SummerQuiz extends PureComponent {
  render() {
    return (
      <Layout page="step-into-summer-with-haka">
        <Helmet
          title="Summer Quiz"
          meta={[
            {
              name: 'description', content: 'Discover Your Perfect Summer Adventure and get a chance to win a tour!*',
            },
            { name: 'og:title', content: 'Summer Quiz' },
            {
              name: 'og:description', content: 'Discover Your Perfect Summer Adventure and get a chance to win a tour!*',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/summer-quiz/` }]}
        />

        <Banner modifier={'overlay-20'} backgroundImage="https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e01b/DSCF5751-2-large_(4)_original.jpg">
          <h1 className="c-heading c-heading--h1">STEP INTO SUMMER WITH HAKA</h1>
        </Banner>

        <section className="l-section" style={{ paddingBottom: '3rem' }}>
          <div className="l-container l-container--small u-text--center">
            <p>Our Summer Quiz competition has now ended. Thanks to the many of you that took part. The winner will be announced shortly but in the meantime you can still discover which is your ideal Haka Tour. </p>
            <img src='https://hakatours-staging.imgix.net/64cde35b0f7aca0001757db4/Which_Haka_Tour_would_suit_you_best_(3)_original.png' style={{ padding: '3rem 0' }} />
            <Link
              to={'https://hakatours.com/blog/which-haka-tour-is-ideal-for-you-a-comprehensive-guide/'}
              className="c-button c-button--primary c-button--wide mt-4"
              target='_blank'
            >
              Read Blog
            </Link>
            <p style={{ paddingTop: '1rem' }}>Alternatively if you’re looking to travel soon, check out our <a href='https://hakatours.com/discounts/' target='_blank'>Last Minute Deals.</a></p>
            <p>We hope to see you on tour with us this summer!</p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default SummerQuiz;
